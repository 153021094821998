<template>
    <RecordFilter />
</template>

<script>
import RecordFilter from '@/components/settings/recordFilter/index.vue';

export default {
    name: 'SettingsRecordFilter',
    components: {
        RecordFilter
    },
};
</script>
